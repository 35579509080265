<template>
  <v-navigation-drawer
    v-if="data"
    :key="`${data.id}:${data.name}`"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 400"
    app
    right
    temporary
    class="elevation-8"
  >
    <organism-form
      :key="data.id"
      ref="organismForm"
      :organism="data"
      info
      @loading="loading = $event"
      @organism-updated="() => {
        $root.$emit('fetch-organisms')
        $emit('close-drawer')
        loading = false
      }"
    />
    <template
      v-if="$auth.check({ organisms: 'edit' })"
      #append
    >
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="$refs.organismForm.updateOrganism()"
        >
          {{ $t('Update') }} Organism
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  export default {
    components: {
      OrganismForm: () => import('@/components/organism/OrganismForm.vue'),
    },
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        organism: this.data,
        valid: false,
        loading: false,
      }
    },
  }
</script>
